// Colors
$gold: #cc993f;
$red1: #ba243b;
$red2: #9f1c37;
$red3: #7f1730;

body {
  background-image: url("./static/bg.jpg");
  background-size: cover;
  color: $gold;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-color: $red3;
  background-repeat: no-repeat;
}

@media screen and (min-width: 600px) {
  .App {
    max-width: 410px;
    max-height: 900px;
    margin: auto;
  }
}

.intro-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  overflow: hidden;

  .intro-box {
    width: 80%;
    margin: 1em auto;
    background-color: white;
    text-align: center;
    padding: 0.5em;

    @media screen and (min-width: 600px) {
      width: 45%;
    }

    .box-inner {
      border: 2px solid $red3;
      h3 {
        margin: 0;
        color: black;
        text-transform: uppercase;
        margin: 0.25em 1em;
        font-weight: 900;
        font-size: 1.4em;
      }

      img {
        max-width: 6rem;
        margin: 1em auto;
      }
    }
  }

  h4 {
    margin: 0;
    text-transform: uppercase;
    color: white;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  height: 6em;
  padding-right: 1.5em;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    width: 3em;
  }

  img {
    max-width: 5em;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}

.header-text {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}

.body-container {
  position: relative;
  .packet {
    width: 100%;
    img {
      width: 100%;
    }
  }

  .numbers {
    position: absolute;
    font-size: 1.5em;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2em;
    margin-top: 6em;

    li {
      list-style-type: none;
      color: white;
      font-family: monospace;
      font-weight: 300;
      margin: 0.7em;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 320px) {
        margin: 0.4em;
      }
      position: relative;
      width: 25px;
      height: 25px;

      .after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: $gold;
        border-radius: 100px;
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: all 0.5s;
      }

      &.active {
        .after {
          opacity: 0;
        }
      }
    }
  }

  .packet-title {
    position: absolute;
    margin-top: 5em;
    top: 0;
    width: 100%;
    text-align: center;

    h2 {
      letter-spacing: 0.5em;
      font-size: 1.8em;
    }

    h4 {
      font-size: 0.5em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }

  .packet-footer {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    margin-bottom: 2em;

    p {
      max-width: 88%;
      margin: 0.5em auto;
      font-size: 0.5em;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
  }
}

.footer {
  margin: 2em auto;
  width: 65%;

  h3 {
    text-align: center;
    color: $red2;
  }

  .icons {
    display: flex;
    justify-content: space-between;

    img {
      max-width: 3em;
    }
  }
}

.email-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .email-box {
    width: 65%;
    padding: 2em;
    color: white;
    background-color: $gold;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;

    h1 {
      font-size: 1.4em;
      text-transform: uppercase;
    }

    input {
      padding: 1em;
      font-size: 1em;
    }

    .email-button {
      width: 33%;
      border: 2px solid white;
      border-radius: 12px;
      font-size: 1.3em;
      color: white;
      text-transform: uppercase;
      font-weight: 700;
      background: transparent;
      padding: 0.5em 0.2em;
      margin: 1em auto;
    }
  }
}

.final-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .final-nums {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin: 1em auto;
    margin-top: 17em;
    position: relative;

    li {
      color: white;
      font-size: 1.2em;
      background-color: $gold;
      width: 45px;
      height: 45px;
      justify-content: center;
      align-items: center;
      display: flex;
      border-radius: 100px;
    }
  }

  .final-alert {
    width: 58%;
    margin: 1em auto;
    background: $gold;
    text-align: center;
    color: white;
    text-transform: uppercase;
    padding: 0.8em;
    margin-top: 4em;
    border-radius: 19px;
    font-size: 1.4em;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.3);
  }

  @media screen and (max-width: 340px) {
    .final-nums {
      margin-top: 13em;
      li {
        width: 25px;
        height: 25px;
        font-size: 0.8em;
      }
    }

    .final-alert {
      font-size: 1em;
      padding: 0.8em;
    }
  }

  @media screen and (max-width: 400px) {
    .final-nums {
      margin-top: 13em;
    }

    .final-alert {
      margin-top: 2em;
    }
  }
}

@media screen and (min-width: 400px) {
  .body-container {
    .packet-title {
      margin-top: 7em;
    }

    .numbers {
      margin-top: 8em;
    }
  }
}

@media screen and (max-width: 320px) {
  .body-container {
    .packet-title {
      h2 {
        margin-bottom: 0.3em;
      }

      h4 {
        margin: 0;
      }
    }

    .numbers {
      margin-top: 5em;
    }
  }
}

@media screen and (max-width: 378px) and (min-width: 323px) {
  .body-container {
    .packet-title {
      margin-top: 6em;
      h2 {
        margin-bottom: 0em;
      }
    }
  }
}
